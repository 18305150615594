<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import AccountSetting from "./AccountSetting";
import RoleSetting from "./RoleSetting";
import FunctionSetting from "./FunctionSetting";
import AuditList from "./AuditList";

export default {
  name: "totalSpendRuralTabsVue",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「權限管理」",
      titles: [
        {
          title: "帳號管理",
          comps: "AccountSetting",
          isActive: false,
          functionId: "50001",
        },
        {
          title: "角色權限管理",
          comps: "RoleSetting",
          isActive: false,
          functionId: "50020",
        },
        {
          title: "功能管理",
          comps: "FunctionSetting",
          isActive: false,
          functionId: "50003",
        },
        {
          title: "稽核紀錄",
          comps: "AuditList",
          isActive: false,
          functionId: "50005",
        },
      ],
    };
  },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  components: {
    VueTabs,
    VTab,
    AccountSetting,
    RoleSetting,
    FunctionSetting,
    AuditList,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
