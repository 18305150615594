<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        帳號管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="帳號狀態">
          <b-select
            :options="[
              { value: null, text: '全部' },
              { value: true, text: '啟用' },
              { value: false, text: '未啟用' },
            ]"
            v-model="searchIsActive"
            @change="getData"
          ></b-select>
        </b-input-group>

        <b-input-group prepend="使用者帳號">
          <b-input v-model="searchAccount" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="帳號姓名">
          <b-input
            v-model="searchAccountName"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增帳號</b-button>
      </div>
      <div class="dtc-search1">
        <b-input-group prepend="系統角色">
          <treeselect
            style="width: 427px;color:#333"
            v-model="searchRole"
            :options="roleOptionsForSearch"
            placeholder="搜尋系統角色"
            noResultsText="查無此系統角色.."
          />
        </b-input-group>
        <b-input-group prepend="隸屬單位">
          <treeselect
            style="width: 507px;color:#333"
            v-model="searchUnit"
            :options="healthNameOptions"
            placeholder="搜尋單位代碼或名稱"
            noResultsText="查無此單位代碼或名稱.."
            :disabled="healthCodeCheck"
          />
        </b-input-group>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding:0.5px !important">
        <b-button
          variant="success"
          size="sm"
          @click="resetPassword(item)"
          class="mr-2"
          >重置密碼</b-button
        >
        <b-button
          variant="primary"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯帳號</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="deleteAccount(item)"
          class="mr-2"
          >刪除帳號</b-button
        >
      </div>

      <div :title="item.showHealthName">
        {{ item.showHealthName || "" }}
      </div>
      <div :title="item.Account">
        {{ item.Account || "" }}
      </div>
      <div :title="item.Name">
        {{ item.Name || "" }}
      </div>
      <div :title="item.showRoleName">
        {{ item.showRoleName || "" }}
      </div>
      <div>
        {{ item.IsActive ? "啟用" : "未啟用" }}
      </div>
      <div :title="item.Telphone" style="text-align:left;padding-left:7px">
        {{ item.Telphone || "" }}
      </div>
      <div :title="item.Mobile" style="text-align:left;padding-left:7px">
        {{ item.Mobile || "" }}
      </div>
      <div :title="item.Email">
        {{ item.Email || "" }}
      </div>
      <div :title="item.LastActivityDate">
        {{ item.LastActivityDate ? $twDate(item.LastActivityDate) : "" }}
      </div>
      <div :title="item.CreateTime">
        {{ item.CreateTime ? $twDate(item.CreateTime) : "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        class="mt-2"
        :options="rows"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <!-- forSaveData -->
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '編輯'}帳號資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="使用者帳號">
            <b-input
              v-model="modalItem.Account"
              :disabled="modalType == 2"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="帳號姓名" class="two-column">
            <b-input v-model="modalItem.Name"></b-input>
          </b-input-group>
          <b-input-group prepend="市話">
            <b-input v-model="modalItem.Telphone"></b-input>
          </b-input-group>
          <b-input-group prepend="行動電話" class="two-column">
            <b-input v-model="modalItem.Mobile"></b-input>
          </b-input-group>
          <b-input-group prepend="身分證字號" class="one-row">
            <b-input
              v-model="modalItem.Identifier"
              :disabled="modalType == 2"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="E-MAIL" class="one-row">
            <b-input v-model="modalItem.Email"></b-input>
          </b-input-group>

          <b-input-group prepend="隸屬單位" class="one-row">
            <treeselect
              style="width: 640px;color:#333"
              v-model="modalItem.HealthCode"
              :options="healthCodeOptions"
              placeholder="搜尋單位代碼或名稱"
              noResultsText="查無此單位代碼或名稱.."
              :disabled="healthCodeCheck"
            />
          </b-input-group>
          <b-input-group prepend="帳號狀態">
            <b-select
              :options="[
                { value: true, text: '啟用' },
                { value: false, text: '未啟用' },
              ]"
              v-model="modalItem.IsActive"
            ></b-select>
          </b-input-group>

          <b-input-group prepend="系統角色" class="one-row"> </b-input-group>
        </div>
        <div class="flex-box">
          <div
            v-for="(roleItem, roleKey) in modalItem.allRoleIds"
            :key="`RoleIds${roleKey}`"
            class="item-box"
          >
            <b-check switch v-model="roleItem.isActive" class="mt-1 mr-1">
              {{ roleItem.ROLE_NAME }}
            </b-check>
          </div>
        </div>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "隸屬單位", key: "HealthCode", sortDesc: null },
  { name: "使用者帳號", key: "Account", sortDesc: null },
  { name: "帳號姓名", key: "Name", sortDesc: null },
  { name: "系統角色", key: "", sortDesc: null },
  { name: "帳號狀態", key: "IsActive", sortDesc: null },
  { name: "市話", key: "Telphone", sortDesc: null },
  { name: "行動電話", key: "Mobile", sortDesc: null },
  { name: "E-MAIL", key: "Email", sortDesc: null },
  { name: "上次登入日期", key: "LastActivityDate", sortDesc: null },
  { name: "帳號建立日期", key: "CreateTime", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        Account: "",
        Name: "",
        Telphone: "",
        Mobile: "",
        Email: "",
        IsActive: true,
        HealthCode: null,
        LastActivityDate: null,
        RoleIds: [],
      },
      modalType: 1,

      //search
      searchIsActive: null,
      searchUnit: null,
      searchRole: "",
      searchAccount: "",
      searchAccountName: "",
      searchRole: null,

      //searchOptions or ModalOptions
      roleOptions: [],
      healthCodeOptions: [],
      healthNameOptions: [],
      roleOptionsForSearch: [],

      searchUnitOption: "",
    };
  },
  components: {
    Treeselect,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const opsHpitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [
        ...opsHpitalArr,
        ...stuChoolArr,
        ...roleHealthBureauArr,
        ...roleHealthCenter,
      ];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    async getAllHealthCodeOption() {
      const url1 = `SysRole/GetRoleInstitutions?no=Admin`;
      const url2 = `SysRole/GetRoleInstitutions?no=Hospital1`;
      const url3 = `SysRole/GetRoleInstitutions?no=Supervisor`;
      const url4 = `SysRole/GetRoleInstitutions?no=user1`;
      const url5 = `SysRole/GetRoleInstitutions?no=user3`;
      const url6 = `SysRole/GetRoleInstitutions?no=user4`;

      const arr1 = await window.axios.get(url1);
      const arr2 = await window.axios.get(url2);
      const arr3 = await window.axios.get(url3);
      const arr4 = await window.axios.get(url4);
      const arr5 = await window.axios.get(url5);
      const arr6 = await window.axios.get(url6);
      let allArr = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6];
      let allArr1 = allArr.map((s) => ({
        id: s.No,
        label: `${s.Name}【${s.No}】`,
        name: s.Name,
      }));
      let allArr2 = allArr.map((s) => ({
        id: s.Name,
        label: `${s.Name}【${s.No}】`,
        // label: s.Name,
      }));

      const uniqueArr1 = this.getUniqueListBy(allArr1, "id");
      const uniqueArr2 = this.getUniqueListBy(allArr2, "id");

      this.healthCodeOptions = JSON.parse(JSON.stringify(uniqueArr1));
      this.healthNameOptions = JSON.parse(JSON.stringify(uniqueArr2));
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    clearSearch() {
      this.searchIsActive = null;
      this.searchUnit = null;
      this.searchRole = "";
      this.searchAccount = "";
      this.searchAccountName = "";
      this.searchRole = null;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },

    async resetPassword(item) {
      const url = `SysUser/ResetPassword?account=${item.Account}`;

      let result = await Swal.fire({
        title: "",
        html: `<section class='align-left'"><div>是否確定要重置密碼?</div><div>密碼重置為: ${item.Account}</div></section>`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        await window.axios.put(url);
        await this.getData();
        Swal.fire("帳號密碼重置成功", "", "success");
      }
    },
    async deleteAccount(item) {
      const url = `api/SysUser/${item.Id}`;

      let result = await Swal.fire({
        title: "",
        html: `<section class='align-left'"><div>是否確定要刪除此使用者帳號?</div><div>${item.HealthName} ${item.Account}</div></section>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        await window.axios.delete(url);
        await this.getData();
        Swal.fire("帳號刪除成功", "", "success");
      }
    },
    async openModal(type, item) {
      this.modalType = type;

      let arr = this.roleOptions.map((s) => ({
        ROLE_ID: s.No,
        ROLE_NAME: s.Name,
        isActive: false,
      }));

      if (type == 1) {
        this.modalItem = {
          Account: "",
          Name: "",
          Telphone: "",
          Mobile: "",
          Email: "",
          IsActive: true,
          HealthCode: null,
          LastActivityDate: null,
          Identifier: "",
        };
      } else {
        this.modalItem = { ...item };

        // this.modalItem.LastActivityDate = this.$twDate(item.LastActivityDate);
        //把有權限的改成true
        arr = arr.map((s) => {
          s.isActive = item.RoleIds.find((k) => k == s.ROLE_ID) ? true : false;
          return s;
        });
      }
      this.modalItem.allRoleIds = JSON.parse(JSON.stringify(arr));
      if (this.healthCodeCheck) {
        this.modalItem.HealthCode = sessionStorage
          .getItem("darcok")
          .replace(/\"/g, "");
        this.modalItem.HealthName = this.healthCodeOptions.find(
          (s) => s.id == this.modalItem.HealthCode
        ).name;
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/SysUser`;

      let arrRoleIds = this.modalItem.allRoleIds.filter((s) => s.isActive);
      arrRoleIds = arrRoleIds.map((s) => {
        return s.ROLE_ID;
      });
      this.modalItem.RoleIds = JSON.parse(JSON.stringify(arrRoleIds));
      // this.modalItem.LastActivityDate = Boolean(this.modalItem.LastActivityDate)
      //   ? this.$usDate(this.modalItem.LastActivityDate)
      //   : null;

      const obj = {
        ...this.modalItem,
      };
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `系統帳號${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async getData() {
      if (this.healthCodeCheck) {
        const code = sessionStorage.getItem("darcok").replace(/\"/g, "");
        this.searchUnit = this.healthCodeOptions.find((s) => s.id == code).name;
      }

      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (typeof this.searchIsActive == "boolean") {
        qs = this.$equalFilter2("IsActive", qs, this.searchIsActive);
        bQs = true;
      }

      // if (this.searchRole) {
      //   if (bQs) {
      //     qs = this.$appendSubstringofFilter("RoleIds", qs, this.searchRole);
      //   } else {
      //     qs = this.$equalSubstringofFilter("RoleIds", qs, this.searchRole);
      //   }
      //   bQs = true;
      // }
      if (this.searchAccount) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Account", qs, this.searchAccount);
        } else {
          qs = this.$equalSubstringofFilter("Account", qs, this.searchAccount);
        }
        bQs = true;
      }
      if (this.searchAccountName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "Name",
            qs,
            this.searchAccountName
          );
        } else {
          qs = this.$equalSubstringofFilter("Name", qs, this.searchAccountName);
        }
        bQs = true;
      }
      if (this.searchRole) {
        if (bQs) {
          qs = `${qs} and $filter==RoleIds/any(d:d eq '${this.searchRole}')`;
        } else {
          qs = `${qs}&$filter=RoleIds/any(d:d eq '${this.searchRole}')`;
        }
        bQs = true;
      }
      if (this.searchUnit) {
        qs = `${qs}&healthName=${this.searchUnit}`;
      }

      try {
        let { Items, Count } = await window.axios.get(`SysUser/Search${qs}`);
        console.log(Items);
        for (let i = 0; i < Items.length; i += 1) {
          Items[i].rolesName = Items[i].RoleIds.map((s) => {
            return this.roleOptions.find((k) => k.No == s)
              ? this.roleOptions.find((k) => k.No == s).Name
              : "";
          });
        }
        Items = Items.map((s) => {
          s.showRoleName = s.rolesName.toString();
          s.showHealthName = this.healthCodeOptions.find(
            (k) => k.id == s.HealthCode
          )
            ? this.healthCodeOptions.find((k) => k.id == s.HealthCode).name
            : "";
          return s;
        });

        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      const Items = await window.axios.get("SysRole/GetList");
      this.roleOptions = JSON.parse(JSON.stringify(Items));
      let arr = JSON.parse(JSON.stringify(Items));
      arr = arr.map((s) => ({
        id: s.No,
        label: `${s.Name}`,
      }));
      this.roleOptionsForSearch = JSON.parse(JSON.stringify(arr));
    },
  },
  async mounted() {
    await this.getAllHealthCodeOption();
    await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    // "modalItem.isForever"(v) {
    //   if (v) {
    //     this.modalItem.IsActive = true;
    //     this.modalItem.LastActivityDate = null;
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 257px 356px 136px 290px 190px 130px 130px 130px 130px 130px 130px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    padding-top: 5px !important;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 34px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 353px 104px 1fr;
  grid-gap: 10px;
  .one-row {
    grid-column: 1/-1;
  }
  .two-column {
    grid-column: 2/-1;
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  .item-box {
    margin: 5px;
  }
}
</style>
