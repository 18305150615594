<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        稽核紀錄
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="帳號名稱">
          <b-input
            v-model="searchAccountName"
            @keydown.enter="getData"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="功能">
          <b-input v-model="searchFunction" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="時間">
          <date-picker-tw
            :time="searchTimeStart"
            @update="(t) => (searchTimeStart = t)"
          ></date-picker-tw>
          <span class="mt-2">~</span>
          <date-picker-tw
            :time="searchTimeEnd"
            @update="(t) => (searchTimeEnd = t)"
          ></date-picker-tw>
        </b-input-group>
        <!-- <b-input-group prepend="IP">
          <b-input v-model="searchIP" @keydown.enter="getData"></b-input>
        </b-input-group> -->
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
      <div class="dtc-search2">
        <b-input-group prepend="行為">
          <b-select
            :options="[
              { value: null, text: '全部' },
              '新增',
              '修改',
              '刪除',
              '查詢',
            ]"
            v-model="searchAction"
            @change="getData"
          ></b-select>
        </b-input-group>

        <b-input-group prepend="IP">
          <b-input v-model="searchIP" @keydown.enter="getData"></b-input>
        </b-input-group>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div>
        {{ +i + 1 }}
      </div>

      <div :title="item.Account">
        {{ item.Account || "" }}
      </div>
      <div :title="item.Action">
        {{ item.Action || "" }}
      </div>
      <div :title="item.Function">
        {{ item.Function || "" }}
      </div>
      <div :title="item.CreateDate">
        {{ item.CreateDate ? $twDate5(item.CreateDate) : "" }}
      </div>
      <div :title="item.IP">
        {{ item.IP || "" }}
      </div>
      <div :title="item.RoleName">
        {{ item.RoleName || "" }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark || "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        class="mt-2"
        :options="rows"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Swal from "sweetalert2";

const headersContent = [
  { name: "序", key: "", sortDesc: null },
  { name: "帳號名稱", key: "Account", sortDesc: null },
  { name: "帳號行為", key: "Action", sortDesc: null },
  { name: "功能", key: "Function", sortDesc: null },
  { name: "紀錄時間", key: "CreateDate", sortDesc: null },
  { name: "登入IP", key: "IP", sortDesc: null },
  { name: "角色名稱", key: "RoleName", sortDesc: null },
  { name: "執行內容註記", key: "Remark", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        Account: "",
        Name: "",
        Telphone: "",
        Mobile: "",
        Email: "",
        IsActive: true,
        isForever: false,
        LastActivityDate: null,
        RoleIds: [],
      },
      modalType: 1,

      //search
      searchIsActive: null,
      searchAccountName: "",
      searchFunction: "",
      searchTimeStart: "",
      searchTimeEnd: "",
      searchIP: "",
      searchAction: null,

      //searchOptions or ModalOptions
    };
  },
  components: {
    Treeselect,
  },
  computed: {},

  methods: {
    clearSearch() {
      this.searchIsActive = null;
      this.searchAccountName = "";
      this.searchFunction = "";
      this.searchTimeStart = "";
      this.searchTimeEnd = "";
      this.searchIP = "";
      this.searchAction = null;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },

    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.orderBy;

      orderBy.find((s) => s.includes("CreateDate"))
        ? ""
        : orderBy.push("CreateDate desc");

      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchFunction) {
        qs = this.$equalSubstringofFilter("Function", qs, this.searchFunction);
        bQs = true;
      }
      if (this.searchAccountName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "Account",
            qs,
            this.searchAccountName
          );
        } else {
          qs = this.$equalSubstringofFilter(
            "Account",
            qs,
            this.searchAccountName
          );
        }
        bQs = true;
      }

      if (this.searchIP) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("IP", qs, this.searchIP);
        } else {
          qs = this.$equalSubstringofFilter("IP", qs, this.searchIP);
        }
        bQs = true;
      }
      if (this.searchAction) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Action", qs, this.searchAction);
        } else {
          qs = this.$equalSubstringofFilter("Action", qs, this.searchAction);
        }
        bQs = true;
      }
      if (this.searchTimeStart && this.searchTimeEnd) {
        const time1 = this.$usDate(this.searchTimeStart);
        const time2 = this.$usDate(this.searchTimeEnd);
        if (bQs) {
          qs = this.$appendTimeFilter("CreateDate", qs, time1, time2);
        } else {
          qs = this.$equalTimeFilter("CreateDate", qs, time1, time2);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`AuditEvent/List${qs}`);

        // for (let i = 0; i < Items.length; i += 1) {
        //   Items[i].rolesName = Items[i].RoleIds.map((s) => {
        //     return this.roleOptions.find((k) => k.ROLE_ID == s)
        //       ? this.roleOptions.find((k) => k.ROLE_ID == s).ROLE_NAME
        //       : "";
        //   });
        // }
        // Items = Items.map((s) => {
        //   s.showRoleName = s.rolesName.toString();
        //   return s;
        // });

        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getSearchOptions() {
      //   const { Items } = await window.axios.get("api/SysRole");
      //   let uniqueArr = this.$dedup(JSON.parse(JSON.stringify(Items)));
      //   uniqueArr = uniqueArr.filter((s) => s.ACTIVE == "Y");
      //   this.roleOptions = JSON.parse(JSON.stringify(uniqueArr));
      // let { Items: items2 } = await window.axios.get("api​/SysFunction");
      // items2 = items2.map((s) => s.HealthName);
      // let uniqueArr2 = [...new Set(items2)];
      // uniqueArr2.sort();
      // this.healthNameOptions = JSON.parse(JSON.stringify(uniqueArr2));
    },
  },
  async mounted() {
    // await this.getSearchOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 80px 150px 130px 360px 208px 140px 330px 310px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    padding-top: 5px !important;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 34px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1,
.dtc-search2 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}
.dtc-search2 {
  grid-template-columns: 325px 325px;
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 353px 104px 1fr;
  grid-gap: 10px;
  .one-row {
    grid-column: 1/-1;
  }
  .two-column {
    grid-column: 2/-1;
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  .item-box {
    margin: 5px;
  }
}
</style>
